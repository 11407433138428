import styled, { keyframes } from 'styled-components';

import { Chevron } from '~/src/screens/App/Header/CarouselArrow';
import { media, zIndex } from '~/src/styles-constants';

const SLICK_SPACE_BETWEEN_IMAGE = 12;

const openAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const SliderNav = styled.div`
  height: auto;
  width: 100%;
  &:hover ${Chevron} {
    animation: ${openAnimation} 250ms ease-in-out;
    opacity: 1;
  }
  z-index: ${zIndex.marketingHeadersSliderNav};

  ${media.S`
    .slick-slider {
      overflow: hidden;
    }

    .slick-slide {
      padding: 0 ${SLICK_SPACE_BETWEEN_IMAGE}px;
    }
  `}
`;

export const CarouselContainer = styled.div`
  position: relative;
  margin-top: 24px;

  height: calc(100vw / 3);
  max-height: 200px;

  ${media.XL`
    margin-top: 32px;
    margin-left: -${SLICK_SPACE_BETWEEN_IMAGE}px;
  `}

  /* Fix SSR, pour que le slider soit render sur une seule ligne */
  .slick-track {
    display: flex;
    margin: 0;
  }
`;

export const SlickDotContainer = styled.ul`
  > li {
    margin: 0;
    width: 23px;
  }

  &.slick-dots {
    > li button::before {
      font-size: 28px;
      padding: 0;
      opacity: 1;
      color: ${({ theme }) => theme.palette.common.DISABLED};
    }

    > li.slick-active button::before {
      opacity: 1;
      color: ${({ theme }) => theme.palette.common.PRIMARY};
    }
  }

  bottom: -2px;
`;
