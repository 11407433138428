import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';

import Seo from '~/src/common/components/Seo';
import { SeoCategory } from '~/src/common/components/Seo/SeoCategory';
import { useOnSitePickupSlot } from '~/src/common/hooks/use-on-site-pickup-slot';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import { IDS, TagManager } from '~/src/common/services/TagManager';
import Toaster from '~/src/common/services/Toaster';
import { HomeProduct } from '~/src/common/typings/product';
import { useGetCategories, useHome } from '~/src/queries/api-ecom/generated/api-ecom';
import { ToasterMessage } from '~/src/screens/Account/OrdersScreen/OrderItem/layout';
import { CatalogLayoutProps } from '~/src/screens/App/CatalogLayout/CatalogLayout';
import {
  DeliveryItems,
  HeaderMobileContent,
  useDeliveryActions,
} from '~/src/screens/App/Header/Navigation';
import { CATALOG_QUERY_PARAMS } from '~/src/screens/Home/queries';
import { NextPage } from '~/src/typings/next';

import HomeScreenContent from './HomeScreenContent';

const HomeScreen: NextPage = ({ user, numberOfCategories }: CatalogLayoutProps) => {
  const { data: homeData, isError, refetch } = useHome(CATALOG_QUERY_PARAMS);
  const {
    deliveryZoneName,
    deliveryAddress,
    timeSlot,
    isPickupMode,
    handleDeliveryAddressClick,
    handleDeliverySlotClick,
  } = useDeliveryActions();
  const { data: accueilCatData } = useGetCategories({ tags: 'Home' });

  const accueilCat = accueilCatData?.items?.[0];

  const { query, replace, pathname } = useRouter<{
    login?: boolean;
    signup?: boolean;
    ordercancelled?: string;
    iscartopen?: string;
  }>();
  const { show: showAccountManagementModal } = useNiceModal('account-management-modal');
  const { show: openCartModal } = useNiceModal('cart-modal');

  useEffect(() => {
    if (query?.login) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
      showAccountManagementModal();
    }
    if (query?.signup) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
      showAccountManagementModal({ showSignup: true });
    }
  }, [showAccountManagementModal, query?.login, query?.signup]);

  const openCart = useCallback(async () => {
    await replace(pathname, undefined, { shallow: true, scroll: false });

    void openCartModal();
  }, [pathname, openCartModal, replace]);

  useEffect(() => {
    if (query?.ordercancelled) {
      Toaster.notify({
        icon: 'info',
        message: (
          <ToasterMessage>
            <p>{I18n.t('order-item.update-order.toaster.title')}</p>
            <p>{I18n.t('order-item.update-order.toaster.content')}</p>
          </ToasterMessage>
        ),
      });
    }

    if (query?.iscartopen || query?.ordercancelled) {
      void openCart();
    }
  }, [query?.ordercancelled, query?.iscartopen, openCart]);

  useEffect(() => {
    const products = homeData?.items?.flatMap(
      homeCategory => homeCategory.products,
    ) as HomeProduct[];

    TagManager.productsView({
      products,
      listName: IDS.promotions,
      forcedCategory: IDS.promotions,
    });
  }, [homeData?.items]);

  // si on arrive avec une URL de PDR sur place
  useOnSitePickupSlot({
    // redirect home et clean les params
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
      replace(pathname, undefined, { shallow: true, scroll: false });
    },
  });

  const visibleHomeCategory = homeData?.items?.filter(cat => cat.products.length > 0);

  return (
    <>
      {accueilCat ? (
        <SeoCategory category={accueilCat} />
      ) : (
        <Seo
          metaTitle={I18n.t('common.homepage')}
          metaDescr={I18n.t('home.meta-description')}
          canonicalUrl="/"
          noSuffix
        />
      )}
      <HeaderMobileContent>
        <DeliveryItems
          deliveryAddress={deliveryAddress}
          deliveryZoneName={deliveryZoneName}
          timeSlot={timeSlot}
          isPickupMode={isPickupMode}
          onDeliveryAddressClick={handleDeliveryAddressClick}
          onDeliverySlotClick={handleDeliverySlotClick}
        />
      </HeaderMobileContent>
      <HomeScreenContent
        userFirstname={user?.firstName}
        homeCategories={visibleHomeCategory}
        numberOfCategories={numberOfCategories}
        isError={isError}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises  -- auto-ignored when updating eslint
        onRefresh={refetch}
        homeCatData={accueilCat}
      />
    </>
  );
};

export default HomeScreen;
