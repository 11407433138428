import styled from 'styled-components';

import Button from '~/src/common/components/Button';
import Icon from '~/src/common/components/Icon';
import { media } from '~/src/styles-constants';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  & > div:last-child {
    flex: 1;
  }
`;

export const MapIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
`;

export const ClockIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
`;

export const DeliveryTime = styled.span<{ $hasLineThrough: boolean }>`
  ${({ $hasLineThrough }) => ($hasLineThrough ? 'text-decoration: line-through' : '')};
`;

export const DeliveryTimeWithDelay = styled.span`
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
`;

export const Title = styled.h5<{ $isCancelled: boolean }>`
  font-size: 16px;
  font-weight: 500;

  margin-right: 20px;

  color: ${({ theme }) => theme.palette.text};

  text-decoration: ${({ $isCancelled }) => ($isCancelled ? 'line-through' : 'none')};

  span {
    text-decoration: none;
  }
`;

export const StatusInformation = styled.p<{ $isCancelled: boolean }>`
  margin-left: 10px;
  font-weight: 500;

  color: ${({ $isCancelled, theme }) =>
    $isCancelled ? theme.palette.common.ERRORS : theme.palette.common.PRIMARY};
`;

export const ShopName = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

export const OrderId = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text};
`;

export const TextIcon = styled.div`
  display: flex;
  align-items: center;

  & span:first-child {
    margin-right: 10px;
  }
`;

export const InformationDelivery = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;

  span ~ span {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text};
  }

  ${media.M`
      flex-direction: row;
      justify-content: space-between;
  `}
`;

export const PickupLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span:last-child {
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.common.DISABLED};
  }
`;

export const PickupLabel = styled.span`
  color: ${({ theme }) => theme.palette.primary};
  font-size: 12px;
`;

// ORDER ITEM
export const OrderItemContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background};
  box-shadow: 0 2px 15px 0 ${({ theme }) => theme.palette.common.BLACK_15};
  border-radius: ${({ theme }) => theme.layout.borderRadius};
  overflow: hidden;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const OrderItemCompletedActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 16px;

  & > button:not(:last-child) {
    margin-right: 8px;
  }
`;

export const OrderItemContent = styled.div`
  > div {
    margin: 0 30px;

    &:not(:last-child) {
      border-bottom: solid thin ${({ theme }) => theme.palette.common.BACKGROUND_1};
    }
  }
`;

// SUMMARY
export const SummaryItem = styled.div`
  padding: 20px 0;

  strong {
    font-weight: 600;
  }

  &:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.palette.common.BACKGROUND_3};
  }
`;

export const SummaryItemTitle = styled.p`
  color: ${({ theme }) => theme.palette.common.BLACK};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const SummaryItemText = styled.p`
  color: ${({ theme }) => theme.palette.common.BLACK};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const SeeMapButton = styled(Button)`
  margin-left: -30px;

  span {
    font-size: 12px;
  }
`;

export const PickupDeliveryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

export const DeliveryMode = styled.b`
  font-weight: 500;
`;

export const PickupIcon = styled(Icon)`
  margin-right: 10px;
`;

export const PickupDeliveryInfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > p:first-child {
    word-break: break-word;
  }
`;

// INVOICES
export const InvoiceRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-bottom: 15px;

  p {
    margin-bottom: 5px;
    font-weight: 400;
  }

  ${media.M`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      flex: 1;
      padding-right: 5px;
      margin-bottom: 0px;
      font-weight: 400;
    }
  `}
`;

// PICKUP MAP
export const PickupMapContainer = styled.div`
  height: 600px;
  width: 100%;
`;

export const UpdateDialogTitle = styled.div`
  padding: 0 35px;
  font-size: inherit;
  line-height: inherit;

  ${media.M`
      padding: 0;
  `}
`;

export const ToasterMessage = styled.div`
  font-size: 12px;
  line-height: 16px;

  & > p:last-child {
    font-weight: 500;
  }
`;

export const StyledEditButton = styled.div`
  margin: 0;
  padding-left: 10px;
  height: auto;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.common.PRIMARY};
  cursor: pointer;
`;
