import { useLayoutEffect, useRef, useState } from 'react';

const useReadMore = (containerHeight: number) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [collapsed, setCollapsed] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    if (ref.current == null) return;
    if (ref.current.scrollHeight < containerHeight) return;
    setCollapsed(true);
  }, [ref, containerHeight]);

  const toggle = () => setCollapsed(!collapsed);

  return { ref, collapsed, toggle };
};

export default useReadMore;
