const commitmentsRoutes: {
  [key: string]: {
    route: string;
  };
} = {
  engagement: {
    route: 'engagement',
  },
  fromagerie: {
    route: 'engagement/fromagerie',
  },
  primeur: {
    route: 'engagement/primeur',
  },
  traiteur: {
    route: 'engagement/traiteur',
  },
};

export const getRouteMatchingTags = (tags?: string[]) => {
  const firstMatchingTag = tags?.find(tag =>
    Object.keys(commitmentsRoutes).includes(tag.toLowerCase()),
  );

  if (firstMatchingTag == null) return null;

  return `/${commitmentsRoutes[firstMatchingTag.toLowerCase()].route}`;
};
