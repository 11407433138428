import React from 'react';

import FrequentPurchasesIcon from '~/src/common/assets/frequent-purchases.svg';
import LastOrdersIcon from '~/src/common/assets/last-orders.svg';
import I18n from '~/src/common/services/I18n';
import { showNiceModal } from '~/src/common/services/ModalsManager';

import HomeQuickButton from './HomeQuickButton';
import * as S from './layout';

const HomeQuickButtonsSection = ({ userFirstname }: { userFirstname?: string }) => {
  return (
    <S.HomeQuickButtonsSection>
      <S.LabelsContainer>
        <S.Username>
          {I18n.translate({
            value: 'home.welcome',
            userFirstname: userFirstname ? `${userFirstname} ` : '',
          })}
        </S.Username>
        <span>{I18n.t('home.quick-buttons.description')}</span>
      </S.LabelsContainer>
      <S.ButtonsContainer>
        <HomeQuickButton
          Icon={LastOrdersIcon}
          text={I18n.t('home.quick-buttons.last-orders')}
          onClick={() => void showNiceModal('last-orders-panel')}
        />
        <HomeQuickButton
          Icon={FrequentPurchasesIcon}
          text={I18n.t('home.quick-buttons.frequent-purchases')}
          onClick={() => void showNiceModal('frequent-purchases-panel')}
        />
      </S.ButtonsContainer>
    </S.HomeQuickButtonsSection>
  );
};

export default HomeQuickButtonsSection;
