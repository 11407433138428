import styled from 'styled-components';

import Link from '~/src/common/components/Link';
import { P, Astyles, H2 } from '~/src/common/components/Typography';

export const TitleContainer = styled.div`
  margin-bottom: 8px;
`;

export const Description = styled(P)`
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

export const Title = styled(H2)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const KnowMoreLink = styled(Link)`
  ${Astyles};
  text-decoration: underline;
  margin-left: 10px;

  color: ${({ theme }) => theme.palette.common.PRIMARY};
`;

export const EmptyText = styled.p`
  text-align: center;
  font-size: 12px;
  font-style: italic;
  padding-top: 40px;
`;
